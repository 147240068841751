import styled from "styled-components"
import bgTraining from "../../images/training_bg.jpg"

const TrainingStyled = styled.header`
  .heading__compoment {
    &--1 {
      max-width: 900px;
      text-align: center;
      margin: 0 auto 0;
      padding-bottom: 30px;
    }
    &--2 {
      max-width: 900px;
      text-align: center;
      margin: 0 auto 0;
    }
  }
  div[class*="BoxesStyles"],
  div[class*="HeadingStyles"] {
    padding-bottom: 30px;
  }
  .box__text {
    p {
      font-size: 16px;
      padding-top: 20px;
      line-height: 1.8;
    }
  }
  .box__description {
    padding-bottom: 30px;
    line-height: 1.8;
  }
  .training__img {
    background: url(${bgTraining});
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
    padding: 90px 0;
    color: #fff;
  }
  .training__information {
    padding: 50px 0;
  }
  .training__grey {
    padding: 50px 0 100px;
  }
  .training__footer {
    padding: 100px 0;
    .container {
      margin: 0 auto;
      max-width: 670px;
      @media (max-width: 680px) {
        max-width: calc(100% - 40px);
      }
      @media (max-width: 450px) {
        max-width: calc(100% - 20px);
      }
    }
    .heading__compoment--2 {
      font-size: 48px;
      font-weight: 700;
    }
    div[class*="DescriptionStyles"] {
      padding-bottom: 50px;
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`

export default TrainingStyled
