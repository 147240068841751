import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Builder from "../components/builder"
import TrainingStyled from "../components/styles/TrainingStyles"

const TrainingPage = ({ data }) => {
  const {
    builder_white,
    builder_plan,
    builder_information,
    builder_opinion,
    builder_form,
  } = data.wordpressPage.acf

  const sectionFirst = builder_white.builder
  const sectionSecond = builder_plan.builder
  const sectionThird = builder_information.builder
  const sectionFourth = builder_opinion.builder
  const sectionFifthx = builder_form.builder
  const { title, canonical } = data.wordpressPage.yoast

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : "Start - Dapr"}
        {/* {data.yoast.map(meta_value => {
          return (
            <meta
              name={meta_value.name || meta_value.property}
              content={meta_value.content}
            />
          )
        })} */}
      </Helmet>
      <Layout hideFooter={true}>
        <TrainingStyled>
          <section className="background__white training__white ">
            <div className="container">
              <h2 className="heading--1">Szkolenie z analizy ryzyka</h2>
            </div>
            {sectionFirst.map((item, index) => {
              return (
                <Builder
                  key={`builder_white__${index}`}
                  loyaut={item.acf_fc_layout}
                  left_text={item.left_text}
                  left_icon={item.left_icon}
                  left_background={item.left_background}
                  right_text={item.right_text}
                  right_icon={item.right_icon}
                  right_background={item.right_background}
                  text={item.text ? item.text : item.description}
                  type_heading={item.type_heading}
                  data={item.row}
                  opinion={item.opinion}
                  boksy={item.boksy}
                />
              )
            })}
          </section>
          <section className="background__img training__img ">
            {sectionSecond.map((item, index) => {
              return (
                <Builder
                  key={`builder_plan__${index}`}
                  loyaut={item.acf_fc_layout}
                  left_text={item.left_text}
                  left_icon={item.left_icon}
                  left_background={item.left_background}
                  right_text={item.right_text}
                  right_icon={item.right_icon}
                  right_background={item.right_background}
                  text={item.text ? item.text : item.description}
                  type_heading={item.type_heading}
                  data={item.row}
                  opinion={item.opinion}
                  boksy={item.boksy}
                />
              )
            })}
          </section>
          <section className="background__white training__white training__information ">
            {sectionThird.map((item, index) => {
              return (
                <Builder
                  key={`builder_information__${index}`}
                  loyaut={item.acf_fc_layout}
                  left_text={item.left_text}
                  left_icon={item.left_icon}
                  left_background={item.left_background}
                  right_text={item.right_text}
                  right_icon={item.right_icon}
                  right_background={item.right_background}
                  text={item.text ? item.text : item.description}
                  type_heading={item.type_heading}
                  data={item.row}
                  opinion={item.opinion}
                  boksy={item.boksy}
                />
              )
            })}
          </section>
          <section className="background__grey training__grey ">
            {sectionFourth.map((item, index) => {
              return (
                <Builder
                  key={`builder_opinion__${index}`}
                  loyaut={item.acf_fc_layout}
                  left_text={item.left_text}
                  left_icon={item.left_icon}
                  left_background={item.left_background}
                  right_text={item.right_text}
                  right_icon={item.right_icon}
                  right_background={item.right_background}
                  text={item.text ? item.text : item.description}
                  type_heading={item.type_heading}
                  data={item.row}
                  opinion={item.opinion}
                  boksy={item.boksy}
                  seeAll={item.see_all_opinion}
                />
              )
            })}
          </section>
          <section className="background__blue training__blue training__footer ">
            {sectionFifthx.map((item, index) => {
              return (
                <Builder
                  key={`builder_form__${index}`}
                  loyaut={item.acf_fc_layout}
                  left_text={item.left_text}
                  left_icon={item.left_icon}
                  left_background={item.left_background}
                  right_text={item.right_text}
                  right_icon={item.right_icon}
                  right_background={item.right_background}
                  text={item.text ? item.text : item.description}
                  type_heading={item.type_heading}
                />
              )
            })}
          </section>
        </TrainingStyled>
      </Layout>
    </>
  )
}

export default TrainingPage

export const query = graphql`
  query getTrainingPage {
    wordpressPage(title: { eq: "Szkolenie" }) {
      id
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
      acf {
        builder_white {
          builder {
            acf_fc_layout
            text
            type_heading
            left_text
            right_text
            left_background {
              localFile {
                childImageSharp {
                  fixed(width: 650, quality: 90) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                    height
                  }
                }
              }
            }
            right_background {
              localFile {
                childImageSharp {
                  fixed(width: 650, quality: 90) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                    height
                  }
                }
              }
            }
            boksy {
              heading
              description
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 420, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_plan {
          builder {
            acf_fc_layout
            text
            type_heading
            row {
              hour
              description
              ikona {
                localFile {
                  childImageSharp {
                    fixed(width: 20, quality: 90) {
                      base64
                    }
                  }
                }
              }
            }
          }
        }
        builder_information {
          builder {
            acf_fc_layout
            text
            type_heading
            row {
              hour
              description
              ikona {
                localFile {
                  childImageSharp {
                    fixed(width: 20, quality: 90) {
                      base64
                    }
                  }
                }
              }
            }
          }
        }
        builder_opinion {
          builder {
            acf_fc_layout
            text
            type_heading
            see_all_opinion
            opinion {
              name
              posotion
              description
              img {
                localFile {
                  childImageSharp {
                    fixed(width: 110, quality: 90) {
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_form {
          builder {
            acf_fc_layout
            text
            type_heading
            description
          }
        }
      }
    }
  }
`
